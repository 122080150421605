import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import ContentWrapper from '../layout/ContentWrapper';

import { ViewportBreakpoints } from '../cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import Button from '@rotaready/frecl/build/Button';
import Text from '@rotaready/frecl/build/Text';

const InnerContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;
`;

const TextContainer = styled.div`
  margin: 20px 0 40px 0;
`;

const ButtonBox = styled.div`
  margin-top: 40px;
  padding: 0 40px;
  
  button {
    width: 100%;
    margin: 0 0 20px 0;
  }
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 30px;
    padding: 0;
    
    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const GetStarted = () => (
  <ResponsiveContainer>
    <ContentWrapper>
      <InnerContainer>
        <H2 uistyle="brand160" text="Ready to get started?" />

        <TextContainer>
          <Text size="xl" uistyle="grey" text="Let us know what you're looking for below and one of our team will be in touch." />
        </TextContainer>

        <ButtonBox>
          <Link to="/demo">
            <Button uistyle="primary" text="Request demo" size="lg" />
          </Link>
          <Link to="/contact">
            <Button uistyle="primary" text="Speak to the team" size="lg" ghost borderless />
          </Link>
        </ButtonBox>
      </InnerContainer>
    </ContentWrapper>
  </ResponsiveContainer>
);

export default GetStarted;
